import React, { useEffect } from 'react'
import { Router } from 'next/router'

import { PlpProduct } from '@/types'
import { DEFAULT_PAGINATION_MAX } from '@/constant/plp'
import { Grid, ViewMore, ViewMoreWrapper } from '@/components/widgets/ListingPreview.styles'
import ProductTile from '@/components/ProductTile'
import { Spacing, FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import { useRouter } from 'next/router'
import SpeedBumpTile from '../SpeedBumpTile'
import { useProductClicked, useProductListViewed } from '@/hooks/AnalyticsProductHook'
import { useAnalyticsMetaData } from '@/hooks/AnalyticsMetaDataHook'

type ListingPreviewViewMore = {
  label: string
  link: string
}

type ListingPreviewProps = {
  products: PlpProduct[]
  isRecommended: boolean // Indicate if this listing preview widget is a recommendation list
  title?: string
  viewMore?: ListingPreviewViewMore // View more button label, view more handler
  handlerKey?: string
  noSpacing?: boolean
  speedBumpTile?: boolean
  totalProductsCount?: number
  toggleProductListViewed?: boolean
}

const ListingPreview = ({
  products,
  isRecommended,
  title,
  viewMore,
  handlerKey,
  noSpacing = false,
  speedBumpTile = true,
  totalProductsCount,
  toggleProductListViewed,
}: ListingPreviewProps) => {
  const allowViewMore = products.length >= DEFAULT_PAGINATION_MAX
  const router = useRouter()

  const metadata = useAnalyticsMetaData()
  const trackProductListViewed = useProductListViewed(title)
  const trackProductClicked = useProductClicked()

  const isPopularCategory = metadata.getIsPopularCategory()

  useEffect(() => {
    if (products.length && toggleProductListViewed) {
      trackProductListViewed(products, true, isPopularCategory, totalProductsCount)
    }
  }, [products, trackProductListViewed, isPopularCategory, totalProductsCount, toggleProductListViewed])

  // When route change is started, return set isPopularCategory value to default (false)
  useEffect(() => {
    const handleRouteChange = () => {
      if (isPopularCategory) {
        metadata.setIsPopularCategory(false)
      }
    }

    Router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      Router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [metadata, isPopularCategory])

  const selectSpeedBumpVariant = (index: number) => {
    switch (index) {
      case 14:
        return 'dark'
      case 20:
        return 'light'
      case 31:
        return 'dark'
    }
  }

  const selectSpeedBumpText = (index: number) => {
    switch (index) {
      case 14:
        return 'Give yourself some time to think'
      case 20:
        return 'See the world from a new perspective'
      case 31:
        return 'Give them adventure'
    }
  }

  return (
    <>
      {title && (
        <>
          <Heading size="L" color="brand-secondary">
            {title}
          </Heading>
          <Spacing size="M" />
        </>
      )}
      <Grid>
        {products.map(
          (
            {
              id,
              urlSegment,
              images,
              retailPrice,
              operatorName,
              name,
              fromPrice,
              region,
              levy,
              rating,
              canonicalRegionUrlSegment,
              decorators,
              hotDeals,
              primaryRegion,
            },
            index
          ) => {
            const marker = index + 1
            return (
              <React.Fragment key={index}>
                {(marker === 14 || marker === 20 || marker === 31) && speedBumpTile && (
                  //@ts-ignore
                  <SpeedBumpTile variant={selectSpeedBumpVariant(marker)} text={selectSpeedBumpText(marker)} />
                )}
                <ProductTile
                  badges={decorators}
                  hotDeals={hotDeals}
                  id={id}
                  image={images?.[0]}
                  price={retailPrice}
                  canonicalRegionUrlSegment={canonicalRegionUrlSegment}
                  region={region}
                  supplier={operatorName}
                  title={name}
                  // TODO: lang
                  url={
                    urlSegment.indexOf('http') >= 0
                      ? urlSegment
                      : `/en/${canonicalRegionUrlSegment || primaryRegion}/${urlSegment}`
                  }
                  fromPrice={fromPrice}
                  levy={levy}
                  rating={rating}
                  onClick={() =>
                    trackProductClicked({
                      product: products[index],
                      position: index + 1,
                      isRecommended,
                      fromWidget: true,
                      listTitle: title,
                    })
                  }
                />
              </React.Fragment>
            )
          }
        )}
      </Grid>
      {viewMore && allowViewMore && (
        <>
          <Spacing size="L" />
          <ViewMoreWrapper>
            <ViewMore alternate size="S" onClick={() => router.push(viewMore.link)}>
              {viewMore.label}
            </ViewMore>
          </ViewMoreWrapper>
        </>
      )}
      {!noSpacing && <Spacing size="XXXL" />}
    </>
  )
}

export default ListingPreview
