import FakeCheckbox from '@/components/FakeCheckbox'
import Icon from '@/components/Icons'
import {
  PricingButtons,
  PriceFilter,
  CheckboxFilters,
  GroupHeader,
  GroupHeaderToggle,
  GroupSublinks,
} from '@/components/widgets/FiltersListingPreview.styles'
import { PRICING_FILTERS } from '@/constant/plp'
import {
  FiltersListingPreviewEntityType,
  PlpProduct,
  SearchPropertiesCategoryEntity,
  SearchPropertiesEntity,
  WidgetCategoryLink,
  WidgetRegionLink,
} from '@/types'
import React from 'react'
import { scrollToElement } from '@/helpers/UtilHelper'
import { BREAKPOINT_SMALL } from '@big-red-group/storefront-common-ui'

const isMobile = () => typeof window !== 'undefined' && window.innerWidth < BREAKPOINT_SMALL
const scrollToTopOfResults = () => {
  if (isMobile()) {
    setTimeout(() => {
      scrollToElement('productsHeadingWrapper', 50)
    }, 100)
  }
}

export const filterHeading = (entityType: FiltersListingPreviewEntityType, name?: string) => {
  if (name && name.toLowerCase() !== 'categories') return name

  switch (entityType) {
    case 'THINGS_TO_DO':
      return 'Subcategories'
    case 'CATEGORY':
      return 'Subcategories'
    default:
      return name
  }
}

export const renderCategoryFilters = (
  categories: WidgetCategoryLink[],
  toggledCatGroups: number[],
  setToggledCatGroups: (groups: number[]) => void,
  selectedCatFilters: string[],
  setSelectedCatFilters: (categoryFilters: string[]) => void
) => {
  const toggleGroup = (catId: number) => {
    updateState({
      currentState: toggledCatGroups,
      value: catId,
      setNewState: setToggledCatGroups,
      shouldScrollToTop: false,
    })
  }

  const updateCatSelection = (catUrl: string[]) => {
    updateState({
      currentState: selectedCatFilters,
      value: catUrl,
      setNewState: setSelectedCatFilters,
      shouldScrollToTop: true,
    })
  }

  return (
    <CheckboxFilters>
      {categories.map((category, catIndex) => {
        return (
          <React.Fragment key={catIndex}>
            <GroupHeader>
              <FakeCheckbox
                label={category.name}
                checked={selectedCatFilters.includes(category.link)}
                onClick={() =>
                  updateCatSelection([
                    category.link,
                    ...(category.subcategories?.flatMap((subcat) => subcat.link) ?? []),
                  ])
                }
              />
              {category.subcategories && category.subcategories.length > 1 && (
                <GroupHeaderToggle onClick={() => toggleGroup(catIndex)} active={toggledCatGroups.includes(catIndex)}>
                  <Icon name="caretDown" />
                </GroupHeaderToggle>
              )}
            </GroupHeader>
            {category.subcategories && toggledCatGroups.includes(catIndex) && (
              <GroupSublinks>
                {category.subcategories?.map((subcategory, subcatIndex) => (
                  <FakeCheckbox
                    key={subcatIndex}
                    label={subcategory.name}
                    checked={selectedCatFilters.includes(subcategory.link)}
                    onClick={() => updateCatSelection([subcategory.link])}
                  />
                ))}
              </GroupSublinks>
            )}
          </React.Fragment>
        )
      })}
    </CheckboxFilters>
  )
}

export const renderRegionFilters = (
  regions: WidgetRegionLink[],
  toggledRegionGroups: number[],
  setToggledRegionGroups: (groups: number[]) => void,
  selectedRegionFilters: string[],
  setSelectedRegionFilters: (regionFilters: string[]) => void
) => {
  const toggleGroup = (catId: number) => {
    updateState({
      currentState: toggledRegionGroups,
      value: catId,
      setNewState: setToggledRegionGroups,
      shouldScrollToTop: false,
    })
  }

  const updateRegionSelection = (regionUrls: string[]) => {
    updateState({
      currentState: selectedRegionFilters,
      value: regionUrls,
      setNewState: setSelectedRegionFilters,
      shouldScrollToTop: true,
    })
  }

  return (
    <CheckboxFilters>
      {regions.map((region, regionIndex) => {
        return (
          <React.Fragment key={regionIndex}>
            <GroupHeader>
              <FakeCheckbox
                label={region.name}
                checked={[region, ...(region.regions || [])]?.some((subRegion) =>
                  selectedRegionFilters.includes(subRegion.link)
                )}
                onClick={() =>
                  updateRegionSelection([
                    ...(region.regions?.flatMap((subregion) => subregion.link) ?? []),
                    region.link,
                  ])
                }
              />
              {region.regions && regions.length > 1 && (
                <GroupHeaderToggle
                  onClick={() => toggleGroup(regionIndex)}
                  active={toggledRegionGroups.includes(regionIndex)}
                >
                  <Icon name="caretDown" />
                </GroupHeaderToggle>
              )}
            </GroupHeader>
            {region.regions && (toggledRegionGroups.includes(regionIndex) || regions.length === 1) && (
              <GroupSublinks>
                {region.regions?.map((subregion, subregionIndex) => (
                  <FakeCheckbox
                    key={subregionIndex}
                    label={subregion.name}
                    checked={selectedRegionFilters.includes(subregion.link)}
                    onClick={() => {
                      updateRegionSelection([subregion.link])
                    }}
                  />
                ))}
              </GroupSublinks>
            )}
          </React.Fragment>
        )
      })}
    </CheckboxFilters>
  )
}

export const renderPriceFilters = (
  pricingMax: number,
  priceRange: number[],
  setPriceRange: (newPriceRange: number[]) => void
) => {
  const availablePrices = PRICING_FILTERS.filter(({ min }) => pricingMax >= min)

  const isActive = (min: number, max: number) => {
    if (priceRange[0] === min && priceRange[1] === max) {
      return true
    }
    return false
  }

  return (
    <PricingButtons>
      {availablePrices.map(({ min, max, label }, idx) => (
        <PriceFilter
          key={idx}
          isActive={isActive(min, max)}
          onClick={() => {
            setPriceRange([min, max])
            scrollToTopOfResults()
          }}
        >
          {label}
        </PriceFilter>
      ))}
    </PricingButtons>
  )
}

type StateProps<T extends number | string> = {
  currentState: T[]
  value: T | T[]
  setNewState: (newState: T[]) => void
  shouldScrollToTop?: boolean
}

function updateState<T extends number | string>({
  currentState,
  value,
  setNewState,
  shouldScrollToTop = true,
}: StateProps<T>): void {
  if (Array.isArray(value)) {
    const valuesToAdd = value.filter((v) => !currentState.includes(v))
    const valuesToRemove = value.filter((v) => currentState.includes(v))

    if (valuesToRemove.length > 0) {
      setNewState(currentState.filter((item) => !value.includes(item)))
    } else {
      setNewState([...currentState, ...valuesToAdd])
    }
  } else {
    const newState = currentState.includes(value)
      ? currentState.filter((item) => item !== value)
      : [...currentState, value]

    setNewState(newState)
  }

  // Scroll to listing results when filters change (checkbox selection), not expand/collapse toggle
  if (shouldScrollToTop) {
    scrollToTopOfResults()
  }
}

export const getUnique = <T,>(arr: T[]): T[] => [...new Set(arr)]

export const getProductCategories = (products: PlpProduct[]): string[] => {
  if (!products?.length) return []
  return getUnique(
    products
      .flatMap((product) => [...(product.categoryUrlSegments ?? []), ...(product.subcategoryUrlSegments ?? [])])
      .filter(Boolean)
  )
}

export const getFilteredCategories = (
  products: PlpProduct[],
  searchCategories?: SearchPropertiesCategoryEntity[]
): WidgetCategoryLink[] | undefined => {
  const productCategories = getProductCategories(products)
  if (productCategories.length === 0 || !searchCategories?.length) return undefined

  return searchCategories
    .filter((category) => {
      return (
        category.categoryType === 'EXPERIENCE' &&
        productCategories.some(
          (pc) => category.urlSegment === pc || category.subCategories?.some((sub) => sub.urlSegment === pc)
        )
      )
    })
    .map((a) => ({
      id: a.id,
      name: a.name,
      link: a.urlSegment,
      subcategories: a.subCategories
        ?.filter((sub) => productCategories.includes(sub.urlSegment))
        .map((s) => ({
          id: s.id,
          name: s.name,
          link: s.urlSegment,
        })),
    }))
}

export const getRegionFiltersFromProducts = (
  products: PlpProduct[],
  searchRegions: SearchPropertiesEntity[]
): WidgetRegionLink[] => {
  return searchRegions
    .filter((searchRegion) => {
      return products.some((product) => product.canonicalRegionUrlSegment === searchRegion.urlSegment)
    })
    .map((searchRegion) => {
      return {
        id: searchRegion.id,
        name: searchRegion.name,
        link: searchRegion.urlSegment,
      }
    })
}
