import { Wrapper } from './Select.styles'

type SelectProps = {
  options: { label: string; value: string | number; shortName?: string; disabled?: boolean }[]
  onChange: (value: string) => void
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
  value?: string | number
  name?: string
  isDisabled?: boolean
  prefixSelect?: boolean
}

const Select: React.FC<SelectProps> = ({
  options,
  onChange,
  value,
  name,
  isDisabled,
  prefixSelect = false,
  onBlur,
  onFocus,
}) => {
  return (
    <Wrapper prefixSelect={prefixSelect}>
      <select
        disabled={isDisabled}
        data-testid={`select-${name}`}
        name={name}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.currentTarget.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
      >
        {options.map(({ label, value }) => (
          <option value={value} key={label}>
            {prefixSelect ? value : label}
          </option>
        ))}
      </select>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={12} height={12}>
        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
      </svg>
    </Wrapper>
  )
}

export default Select
