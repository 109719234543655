import styled from 'styled-components'
import { BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL } from '@/constant/ui'
import { Badges, ProductImageWrap, Wrapper as ProductTile } from '@/components/ProductTile.styles'
import Button from '@/components/Button'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 40px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    grid-template-columns: 1fr;
    gap: 1px;
    background: var(--brand-accent-secondary-dark);
    width: calc(100% + 32px);
    left: -16px;
    position: relative;

    ${ProductTile} {
      padding: 14px 16px;
    }
  }

  @media (min-width: ${BREAKPOINT_LARGE}px) {
    a:nth-child(10n - 1),
    a:nth-child(10n) {
      grid-column-end: span 2;

      ${ProductImageWrap} {
        width: 100%;
        height: 520px;
        aspect-ratio: 1/1;
      }

      ${Badges} {
        top: calc(520px - 12px);
      }
    }
  }
`
export const ViewMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ViewMore = styled(Button)`
  min-width: 180px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
  }
`
