import styled from 'styled-components'

export const Wrapper = styled.div<{ prefixSelect: boolean }>`
  position: relative;

  & > select {
    border: 1px solid var(--brand-accent-secondary-dark);
    padding: 17px 50px 17px 16px;
    border-radius: 4px;
    box-sizing: border-box;
    background: white;
    font-size: 15px;
    font-weight: var(--font-regular);
    color: var(--text-primary);
    line-height: 1;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    width: 100%;
    cursor: pointer;
    min-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    fill: var(--black);
  }

  &:focus {
    outline: none;
    border-color: var(--black);
  }

  ${({ prefixSelect }) =>
    prefixSelect &&
    `
    width: 84px;
    position: absolute;
    top: 1px;
    left: 1px;
    height: calc(100% - 1px);

    & > select {
      padding: 14px 12px;
      border-width: 0 1px 0 0;
      border-radius: 4px 0 0 4px;
      height: calc(100% - 1px);
    }
  `}
`

export const Description = styled.p`
  font-weight: var(--font-regular);
`

export const RadioButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`
