import { FilterDropdown, PriceFilter } from '@/types'

export const DEFAULT_PAGINATION_OFFSET = 0
export const DEFAULT_PAGINATION_MAX = 16
export const FILTER_DROPDOWNS: FilterDropdown[] = [
  {
    name: 'all filters',
    isOpen: false,
    enabledPlps: ['ALL', 'SEARCH'],
  },
  {
    name: 'regions',
    isOpen: false,
    enabledPlps: ['THINGS_TO_DO', 'SEARCH'],
  },
  {
    name: 'categories',
    isOpen: false,
    enabledPlps: ['REGION', 'CATEGORY', 'THINGS_TO_DO', 'SEARCH'],
  },
  {
    name: 'price',
    isOpen: false,
    enabledPlps: ['ALL', 'SEARCH'],
  },
  // {
  //   name: 'availability',
  //   isOpen: false,
  //   enabledPlps: ['ALL'],
  // },
]
export const PRICING_FILTERS: PriceFilter[] = [
  { min: 0, max: 9999, label: 'All' },
  { min: 0, max: 49.99, label: '$0 - $50' },
  { min: 50, max: 99.99, label: '$50 - $100' },
  { min: 100, max: 249.99, label: '$100 - $250' },
  { min: 250, max: 499.99, label: '$250 - $500' },
  { min: 500, max: 9999, label: '$500+' },
]
