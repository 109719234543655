import { BREAKPOINT_SMALL } from '@/constant/ui'
import styled from 'styled-components'

export const Wrapper = styled.div<{ variant: 'dark' | 'light' }>`
  text-decoration: none;
  width: 100%;
  height: 100%;
  border: 1px solid var(--bg-black);
  padding: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-wrap: balance;

  ${({ variant }) =>
    variant === 'dark'
      ? `
      background: var(--bg-black);
    `
      : `
      background: var(--bg-white);
  `};

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    min-height: 150px;
    justify-content: center;

    span {
      text-align: center;
    }
  }
`
