import { Wrapper, Label, Tick } from './Checkbox.styles'
import { BrandColor } from '@big-red-group/storefront-common-ui'

type CheckboxProps = {
  label?: string | JSX.Element
  name: string
  value: string
  checked: boolean
  onChange?: any
  tickColor?: BrandColor
  size?: 'standard' | 'large'
  customLabel?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({ label, name, value, checked, onChange, tickColor, size = 'standard' }) => {
  return (
    <Wrapper size={size}>
      <input type="checkbox" id={name} name={name} value={value} defaultChecked={checked} onChange={onChange} />
      <Tick htmlFor={name} tickColor={tickColor} />
      {label && <Label htmlFor={name}>{label}</Label>}
    </Wrapper>
  )
}

export default Checkbox
