import { useContext } from 'react'
import { DefaultPropertiesContext } from '@/context/DefaultPropertiesContext'
import { Wrapper } from './SpeedBumpTile.styles'
import { FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'

const SpeedBumpTile: React.FC<{ variant: 'dark' | 'light'; text: string }> = ({ variant, text }) => {
  return (
    <Wrapper data-testid="speedbump" variant={variant}>
      <Heading as="span" color={variant === 'dark' ? 'brand-primary' : 'text-primary'}>
        {text}
      </Heading>
    </Wrapper>
  )
}

export default SpeedBumpTile
