import { Wrapper, Label, Tick } from './FakeCheckbox.styles'

type FakeCheckboxProps = {
  label: string
  checked: boolean
  id?: string
  onClick?: any
}

const FakeCheckbox: React.FC<FakeCheckboxProps> = ({ id, label, checked, onClick }) => {
  return (
    <Wrapper id={id} checked={checked} onClick={onClick}>
      <Tick />
      <Label>{label}</Label>
    </Wrapper>
  )
}

export default FakeCheckbox
