import React, { SetStateAction, Dispatch, useState, ChangeEvent } from 'react'
import {
  FiltersListingPreviewEntityType,
  PlpProduct,
  SearchFilterDecorator,
  SortBy,
  WidgetCategoryLink,
  WidgetRegionLink,
} from '@/types'
import {
  Section,
  SectionTitle,
  Wrapper,
  IconWrapper,
  InclusionWrapper,
  InclusionSection,
  CheckboxSection,
  Footer,
  FooterButtons,
  SectionWrapper,
  MobileHeaderIcon,
} from './ListingFilters.styles'
import Icon from './Icons'
import { Heading, Body, useSwipeClose, MobileFilterHeader } from '@big-red-group/storefront-common-ui'

import Button from './Button'
import { searchFiltersDecorators, decoratorsIcons } from '@/helpers/DecoratorHelper'
import { filterHeading, renderCategoryFilters, renderPriceFilters, renderRegionFilters } from '@/helpers/FilterHelper'
import { Close, GroupHeaderToggle } from './widgets/FiltersListingPreview.styles'
import Checkbox from './Checkbox'

type ListingFiltersProps = {
  onClose: () => void
  setProducts: any
  handleFilter: () => void
  reset: () => void
  originalResults: PlpProduct[]
  hotDealsEnabled: boolean
  availableInclusions: string[]
  selectedInclusions: string[]
  setHotDealsEnabled: Dispatch<SetStateAction<boolean>>
  setSelectedInclusions: Dispatch<SetStateAction<SearchFilterDecorator[]>>
  pricingMax: number
  priceRange: number[]
  setPriceRange: Dispatch<SetStateAction<number[]>>
  onSortProp?: SortBy
  regions: WidgetRegionLink[] | undefined
  categories: WidgetCategoryLink[] | undefined
  toggledCatGroups: number[]
  setToggledCatGroups: Dispatch<SetStateAction<number[]>>
  selectedCatFilters: string[]
  setSelectedCatFilters: Dispatch<SetStateAction<string[]>>
  toggledRegionGroups: number[]
  setToggledRegionGroups: Dispatch<SetStateAction<number[]>>
  selectedRegionFilters: string[]
  setSelectedRegionFilters: Dispatch<SetStateAction<string[]>>
  entityType: FiltersListingPreviewEntityType
  userChangedFilterRef: React.MutableRefObject<boolean>
}

const ListingFilters: React.FC<ListingFiltersProps> = ({
  onClose,
  setProducts,
  handleFilter,
  reset,
  hotDealsEnabled,
  availableInclusions,
  selectedInclusions,
  setHotDealsEnabled,
  setSelectedInclusions,
  originalResults,
  pricingMax,
  priceRange,
  setPriceRange,
  onSortProp,
  regions,
  categories,
  toggledCatGroups,
  setToggledCatGroups,
  selectedCatFilters,
  setSelectedCatFilters,
  toggledRegionGroups,
  setToggledRegionGroups,
  selectedRegionFilters,
  setSelectedRegionFilters,
  entityType,
  userChangedFilterRef,
}) => {
  const [categoriesOpen, setCategoriesOpen] = useState(true)
  const [regionsOpen, setRegionsOpen] = useState(true)
  const [priceOpen, setPriceOpen] = useState(true)
  const [specialsOpen, setSpecialsOpen] = useState(true)
  const [additionalOpen, setAdditionalOpen] = useState(true)
  const { handleTouchStart, handleTouchEnd } = useSwipeClose(onClose)

  const handleHotDeals = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const isChecked: boolean = event.target.checked
    userChangedFilterRef.current = true
    setHotDealsEnabled(isChecked)
    handleFilter()
  }

  const handleInclusions = (inclusion: SearchFilterDecorator) => {
    const newSelectedInclusions = selectedInclusions.includes(inclusion)
      ? selectedInclusions.filter((selectedInclusion) => selectedInclusion !== inclusion)
      : [...selectedInclusions, inclusion]
    userChangedFilterRef.current = true
    setSelectedInclusions(newSelectedInclusions as SearchFilterDecorator[])
    handleFilter()
  }

  const handlePriceRangeChange = (range: number[]) => {
    userChangedFilterRef.current = true
    setPriceRange(range)
    handleFilter()
  }

  const handleCategoryChange = (filters: string[]) => {
    userChangedFilterRef.current = true
    setSelectedCatFilters(filters)
    handleFilter()
  }

  const handleRegionChange = (filters: string[]) => {
    userChangedFilterRef.current = true
    setSelectedRegionFilters(filters)
    handleFilter()
  }

  const showCategoryFilters = (categories: WidgetCategoryLink[]): boolean => {
    if (!categories?.length) {
      return false
    }

    return categories.length > 1 || (categories[0]?.subcategories?.length ?? 0) > 1
  }

  return (
    <Wrapper className="filter-con-inside" data-testid="filter-data-all" id="refine-popup">
      <MobileFilterHeader
        isAllFilters={true}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        testIdSuffix="all"
      >
        <Heading as="p" size="L">
          All filters
        </Heading>
        <Close onClick={onClose} data-testid="filter-close-all">
          <Icon name="close" />
        </Close>
      </MobileFilterHeader>
      <SectionWrapper>
        {categories && showCategoryFilters(categories) && (
          <Section>
            <SectionTitle>
              {filterHeading(entityType, 'Categories')}
              <GroupHeaderToggle
                onClick={() => setCategoriesOpen(!categoriesOpen)}
                active={categoriesOpen}
                openByDefault
              >
                <Icon name="caretDown" />
              </GroupHeaderToggle>
            </SectionTitle>
            {categoriesOpen && (
              <CheckboxSection>
                {renderCategoryFilters(
                  categories,
                  toggledCatGroups,
                  setToggledCatGroups,
                  selectedCatFilters,
                  handleCategoryChange
                )}
              </CheckboxSection>
            )}
          </Section>
        )}
        {regions && Boolean(regions.length) && (
          <Section>
            <SectionTitle>
              Regions
              <GroupHeaderToggle onClick={() => setRegionsOpen(!regionsOpen)} active={regionsOpen} openByDefault>
                <Icon name="caretDown" />
              </GroupHeaderToggle>
            </SectionTitle>
            {regionsOpen && (
              <CheckboxSection>
                {renderRegionFilters(
                  regions,
                  toggledRegionGroups,
                  setToggledRegionGroups,
                  selectedRegionFilters,
                  handleRegionChange
                )}
              </CheckboxSection>
            )}
          </Section>
        )}
        <Section>
          <SectionTitle>
            Price range
            <GroupHeaderToggle onClick={() => setPriceOpen(!priceOpen)} active={priceOpen} openByDefault>
              <Icon name="caretDown" />
            </GroupHeaderToggle>
          </SectionTitle>
          {priceOpen && renderPriceFilters(pricingMax, priceRange, handlePriceRangeChange)}
        </Section>
        <Section>
          <SectionTitle>
            Specials
            <GroupHeaderToggle onClick={() => setSpecialsOpen(!specialsOpen)} active={specialsOpen} openByDefault>
              <Icon name="caretDown" />
            </GroupHeaderToggle>
          </SectionTitle>
          {specialsOpen && (
            <CheckboxSection>
              <Checkbox
                label="On Sale"
                name="applyCredit"
                value="true"
                checked={hotDealsEnabled}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleHotDeals(event)}
                tickColor="brand-secondary"
              />
            </CheckboxSection>
          )}
        </Section>
        <Section>
          <SectionTitle>
            Additional filters
            <GroupHeaderToggle onClick={() => setAdditionalOpen(!additionalOpen)} active={additionalOpen} openByDefault>
              <Icon name="caretDown" />
            </GroupHeaderToggle>
          </SectionTitle>
          {additionalOpen && (
            <InclusionSection>
              {searchFiltersDecorators.map(({ decorator, label }) => {
                return (
                  availableInclusions.includes(decorator) && (
                    <InclusionWrapper key={decorator}>
                      <IconWrapper
                        active={selectedInclusions.includes(decorator)}
                        onClick={() => {
                          handleInclusions(decorator)
                        }}
                        data-testid={`inclusion-${decorator}`}
                      >
                        <Icon name={decoratorsIcons[decorator]} />
                      </IconWrapper>
                      <p>{label}</p>
                    </InclusionWrapper>
                  )
                )
              })}
            </InclusionSection>
          )}
        </Section>
      </SectionWrapper>
      <Footer>
        <FooterButtons>
          <Body onClick={reset} weight="medium" dataTestId="filter-clear-all">
            Clear all
          </Body>
          <Button onClick={onClose} dataTestId="filter-apply-all">
            Apply
          </Button>
        </FooterButtons>
      </Footer>
    </Wrapper>
  )
}

export default ListingFilters
