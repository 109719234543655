import styled from 'styled-components'

export const Label = styled.label`
  cursor: pointer;
  margin-left: 5px;
  font-size: 15px;
`

export const Tick = styled.label`
  background-color: white;
  border: 1px solid var(--brand-accent-secondary-dark);
  border-radius: 4px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transition: 0.2s;
  overflow: hidden;
  flex-shrink: 0;

  &:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    width: 10px;
    height: 4px;
    opacity: 0;
    position: absolute;
    left: 1px;
    bottom: -10px;
    transition: 0.15s ease-in-out;
    transform: rotate(-50deg);
  }
`

export const Wrapper = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 16px;
  text-wrap: balance;

  &:hover {
    ${Tick} {
      border-color: var(--brand-secondary);
    }
  }

  ${({ checked }) =>
    checked &&
    `
    ${Tick} {
      background-color: var(--brand-primary);
      border-color: var(--brand-primary);
      &:after {
        bottom: 6px;
        opacity: 1;
        border-color: var(--brand-secondary);
      }
    }
  `}
`
