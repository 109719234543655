import styled from 'styled-components'
import { BREAKPOINT_SMALL } from '@big-red-group/storefront-common-ui'

export const Wrapper = styled.div`
  background: white;
  overflow: hidden;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding-bottom: 70px;
    overscroll-behavior-y: none;
  }
`

export const Title = styled.div`
  padding: 16px 20px;
  display: none;
  border-bottom: 1px solid var(--grey-100);
  font-size: 12px;
  font-weight: var(--font-regular);
  align-items: center;
  justify-content: space-between;

  & > svg {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`

export const Section = styled.div`
  padding: 20px;
  border-bottom: 1px solid var(--grey-10);

  &:last-child {
    border-bottom: 0;
  }
`

export const SectionWrapper = styled.div``

export const SectionTitle = styled.div`
  font-weight: var(--font-semibold);
  font-size: 17px;
  display: flex;
  cursor: default;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-weight: var(--font-medium);
  }
`

export const CheckboxLabel = styled.label`
  display: block;
  margin-top: 8px;
  font-size: 14px;
  font-weight: var(--font-regular);
  cursor: pointer;

  & > input {
    margin-right: 8px;
  }
`

export const IconWrapper = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--brand-accent-secondary-dark);
  outline: none;
  border-radius: 2px;
  padding: 10px;
  height: 40px;
  width: 40px;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    border-color: var(--brand-secondary);
  }

  ${({ active }) =>
    active &&
    `
      border-color: var(--brand-primary);
      background-color: var(--brand-primary);
      color: var(--brand-secondary);
    `}

  svg {
    width: 20px;
    height: 20px;
  }
`

export const InclusionWrapper = styled.div`
  text-align: center;
  font-size: 12px;

  & > p {
    margin-top: 10px;
  }
`

export const InclusionSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 16px;

  @media (min-width: 769px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
export const CheckboxSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 16px;
`

export const Footer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0px -2px 10px 0px #0000000f;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`
export const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  > p {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      height: 1px;
      width: 100%;
      background: var(--brand-secondary);
    }
  }
`
export const ButtonWrapper = styled.div<{ truncated: boolean }>`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  ${({ truncated }) =>
    truncated &&
    `
    button:nth-child(n+7) {
      display: none;
    }
  `}
`

export const MobileHeaderIcon = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 28px 20px 20px 20px;
  box-sizing: border-box;
  cursor: pointer;

  @media (min-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }
`
